.fullwidth-centered-text-content {
    //
	background-color: $lightest-gray;
	padding: 2.5rem 1rem; /* 72/16 */

	@include above($m) {
		text-align: center;
		padding: 4.5rem 1rem; /* 72/16 */
	}

	&__header {
		&__sub-heading {
			font-weight: 800;
			text-transform: uppercase;
			font-size: 1.25rem;
			margin-bottom: 2.25rem; /* 36/16 */
			line-height: 1.33333;

			.fullwidth-centered-text-content--yellow-theme & {
				color: $yellow;
			}
			.fullwidth-centered-text-content--pink-theme & {
				color: $pink;
			}
		}

		&__heading {
			font-size: 2rem;
			font-weight: 900;
			line-height: 1.25;
			margin-bottom: 1.25rem;
		}
	}

	&__content-holder {
		//


		&__content {
			margin: auto;
			max-width: 1000px;
			font-size: 1.5rem;
			line-height: 1.333333;

			@include below($s) {
				font-size: 1rem;
			}

			p,
			span {
				font-size: inherit;
				font-weight: inherit;
				line-height: inherit;
				color: inherit;
			}
			a {
				font-size: inherit;
				font-weight: inherit;
				line-height: inherit;
				color: inherit;
				text-decoration: underline;

				&:hover {
					text-decoration: none;
				}
			}
		}

		&__content-kicker {
			font-size: 1.125rem;
			font-weight: 800;
			line-height: 1.3333333;
			margin-top: 3rem;


			p,
			span {
				font-size: inherit;
				font-weight: inherit;
				line-height: inherit;
				color: inherit;
			}
			a {
				font-size: inherit;
				font-weight: inherit;
				line-height: inherit;
				color: inherit;
				text-decoration: underline;

				&:hover {
					text-decoration: none;
				}
			}
			.fullwidth-centered-text-content--yellow-theme & {
				color: $yellow;
			}
			.fullwidth-centered-text-content--pink-theme & {
				color: $pink;
			}
			.fullwidth-centered-text-content--black-theme & {
				color: $black;
			}
		}
	}
}
