
// Gone is the desktop, table and mobile. Let's use s, m, l, xl.
$xs: 321px;
$s: 500px;
$m: 800px;
$l: 1050px;
$xl: 1410px;

// Default content settings.
$content-padding: 1rem;
$content-max-width: 70rem;

// Font weights.
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;

$stack: 'national2', 'Helvetica Neue', Helvetica, Arial, sans-serif;

// Colors
$black: #000000;
$white: #fff;
$silver: #C7CED3;
$light-gray: #E5E5E5;
$lightest-gray: #FAFAFA;
$gray: #9a9492;
$yellow: #ff734d;
$dark-yellow: darken($yellow, 5%);
$peach: #F0AB87;
$dark-green: #516234;
$green: #AEBD15;
$light-green: #F3FFE1;
$pink: #bf2188;
$red: #bf2188;
$ad-blue: #2C5CA7;

$color-array: ('black', $black) ('white', $white) ('pink', $pink) ('yellow', $yellow) ('light-gray', $light-gray) ('peach', $peach) ('dark-green', $dark-green) ('green', $green) ('light-green', $light-green) ('lightest-gray', $lightest-gray);

//Fonts
@font-face {
	font-family: 'national2';
	src: url('/wp-content/themes/besoksliv-visita/fonts/n2regular.woff2') format('woff2'), url('/wp-content/themes/besoksliv-visita/fonts/n2regular.woff') format('woff');
	font-weight:  400;
	font-style:   normal;
	font-stretch: normal;
}

@font-face {
	font-family: 'national2';
	src: url('/wp-content/themes/besoksliv-visita/fonts/n2regularitalic.woff2') format('woff2'), url('/wp-content/themes/besoksliv-visita/fonts/n2regularitalic.woff') format('woff');
	font-weight:  400;
	font-style:   italic;
	font-stretch: normal;
}

@font-face {
	font-family: 'national2';
	src: url('/wp-content/themes/besoksliv-visita/fonts/n2medium.woff2') format('woff2'), url('/wp-content/themes/besoksliv-visita/fonts/n2medium.woff') format('woff');
	font-weight:  500;
	font-style:   normal;
	font-stretch: normal;
}

@font-face {
	font-family: 'national2';
	src: url('/wp-content/themes/besoksliv-visita/fonts/n2mediumitalic.woff2') format('woff2'), url('/wp-content/themes/besoksliv-visita/fonts/n2mediumitalic.woff') format('woff');
	font-weight:  500;
	font-style:   italic;
	font-stretch: normal;
}

@font-face {
	font-family: 'national2';
	src: url('/wp-content/themes/besoksliv-visita/fonts/n2bold.woff2') format('woff2'), url('/wp-content/themes/besoksliv-visita/fonts/n2bold.woff') format('woff');
	font-weight:  600;
	font-style:   normal;
	font-stretch: normal;
}

@font-face {
	font-family: 'national2';
	src: url('/wp-content/themes/besoksliv-visita/fonts/n2bolditalic.woff2') format('woff2'), url('/wp-content/themes/besoksliv-visita/fonts/n2bolditalic.woff') format('woff');
	font-weight:  600;
	font-style:   italic;
	font-stretch: normal;
}