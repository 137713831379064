.form {
  //
	&__heading {
		font-weight: 900;
		margin-bottom: 1.125rem;
		font-size: 2rem;
	}
	::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: lighten($black, 25%);
	}
	::-moz-placeholder { /* Firefox 19+ */
	  color: lighten($black, 25%);
	}
	:-ms-input-placeholder { /* IE 10+ */
	  color: lighten($black, 25%);
	}
	:-moz-placeholder { /* Firefox 18- */
	  color: lighten($black, 25%);
	}
  &__input {
		font-size: 1.125rem;
	  background-color: $lightest-gray;
	  color: $black;
	  padding: .875rem .75rem .75rem .75rem;
		display: block;
		border: 0;
		width: 100%;
		outline: 0;

	  &-wrap {
		  margin-bottom: 1rem;
	  }
	  &-label {
			display: block;
			font-size: 1.125rem;
		  margin-bottom: 1rem;
		  font-weight: 800;
	  }
  }
	&__button {
		background-color: $black;
		color: $white;
		font-size: .875rem;
		letter-spacing: 3px;
		text-transform: uppercase;
		font-weight: 800;
		margin: 1rem auto;
		padding: .875rem 1.5rem;
		display: inline-block;
		width: auto;
		border: 0;
		transition: background-color .2s ease, opacity .2s ease;

		&:hover {
			background-color: lighten($black, 10%);
		}
		&:disabled {
			opacity: .5;
			pointer-events: none;
		}
		&--fullwidth {
			width: 100%;
			display: block;
		}
		&--pink {
			background-color: $pink;
			color: $white;

			&:hover {
				background-color: darken($pink, 5%);
			}
		}

		&--yellow {
			background-color: $yellow;
			color: $black;

			&:hover {
				background-color: darken($yellow, 5%);
			}
		}

		&--gray {
			background-color: $light-gray;
			color: $black;

			&:hover {
				background-color: darken($light-gray, 5%);
			}
		}
	}

	&__checkbox {
		position: relative;
		&:not(:last-child) {
			margin-right: 1rem;
		}

		&__input {
			visibility: hidden;
				height: 0;
				width: 0;
				opacity: 0;
				position: absolute;
				top: 0;
				left: 0;
		}

		&__label {
			cursor: pointer;
			&-text {
				display: inline-block;
				vertical-align: middle;
				line-height: 1;
			}
			&::before {
				content: '';
				display: inline-block;
				vertical-align: middle;
				border: 2px solid rgba($pink, .5);
				width: 1rem;
				height: 1rem;
				border-radius: 3px;
				background-color: $white;
				margin-right: .5rem;
				text-align: center;
				line-height: 1;
				color: $white;
				transition: border-color .2s ease, background-color .2s ease;
			}

			input[type="checkbox"]:checked + & {
					&::before {
						background-color: $yellow;
						border-color: $yellow;
						background-size: 100% 100%;
						background-position: center center;
						background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTRweCIgaGVpZ2h0PSIxMHB4IiB2aWV3Qm94PSIwIDAgMTQgMTAiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDQ1LjIgKDQzNTE0KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5TaGFwZTwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxkZWZzPjwvZGVmcz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxwb2x5Z29uIGlkPSJTaGFwZSIgZmlsbD0iI0ZGRkZGRiIgcG9pbnRzPSI1IDEwIDAgNS4xOTIzMDc2OSAxLjQgMy44NDYxNTM4NSA1IDcuMzA3NjkyMzEgMTIuNiAtMS4xMzY4NjgzOGUtMTMgMTQgMS4zNDYxNTM4NSI+PC9wb2x5Z29uPgogICAgPC9nPgo8L3N2Zz4=');
					}

				}
		}
	}

	&__message {

		transition: opacity .4s ease .1s, transform .5s ease;
		font-size: 1.5rem;
		font-weight: 700;
		opacity: 1;

		&--hidden {
			opacity: 0;
			transform: translateX(-2rem);
		}
	}

	&__fineprint {
		font-size: .75rem;
		margin: .5rem 0;
	}
}


.other-recipient-adress {
	position: static;
	opacity: 1;
	transition: opacity .2s ease .1s, transform .4s ease;

	&--inactive {
		visibility: hidden;
		opacity: 0;
		position: absolute;
		transform: translateY(-2rem);
	}
}
