.site-footer {
	background-color: $yellow;
	color: $white;

	&__navigation {
		//
		&-menu {
			flex-shrink: 0;
			flex-grow: 0;

			&__holder {
				list-style: none;

				.menu-item {
					text-transform: uppercase;
					font-size: .875rem;
					letter-spacing: 3px;
					cursor: pointer;

					@include below($m) {
						padding-bottom: 1.125rem;
					}
					@include above($m) {
						display: inline-block;
						vertical-align: middle;

						&:not(:last-child) {
							margin-right: 1.25rem;
						}
					}


					&:hover {
						opacity: .75;
					}
					// link element
					a {
						color: $white;
						text-decoration: none;


					}
				}
			}
		}
	}
}

.site-footer__top-section {
		text-align: center;
		padding: 2rem 0 0 0;

		@include below($content-max-width) {
			padding: 2rem 1rem 0 1rem;
		}

		.row {
			&:first-child {
				border-bottom: solid 1px rgba($white, .5);
				margin-bottom: 2.625rem;
				padding-bottom: 2rem;
			}
			&:last-child {
				padding-bottom: 3rem; /* 48/16 */
			}
		}

		&__column {
			//
			.column-grid & {
				@include below($s) {
					margin-bottom: 1.5rem;
					padding-bottom: 1.5rem;

					&:not(:last-child){
						border-bottom: solid 1px rgba($white, .5);
					}
				}
			}

			&__heading {
				text-transform: uppercase;
				margin-bottom: .5rem;
				font-weight: 800;
				font-size: 1rem;
			}

			&__text {
				font-size: 1.125rem;
				line-height: 1.3333;
				max-width: 37.5rem; /* 660/16 */ /* 600/16 */
				margin: auto;
			}

			&__link {
				color: $white;
				border-bottom: solid 2px $white;
				text-decoration: none;

				&:hover {
					border-bottom-color: $white;
					box-shadow: inset 0 -1.25rem 0 $white;
					color: $yellow;
				}

			}
		}
	} // Top section
.site-footer__bottom-section {
		padding: 1.5rem 0 1.5rem 0;
		background-color: $dark-yellow;
		justify-content: space-between;

		@include below($content-max-width) {
			padding: 2rem 1rem;
		}

		@include below($m) {
			text-align: center;
		}

		&__column {
			//
			@include below($m) {
				&:not(:last-child) {
					margin-bottom: 2.5rem;
				}
			}

			&__logos {
				flex-shrink: 1;

				text-align: right;
				@include below($m){
					text-align: center;
				}
				&__logo {
					margin: 0 auto 0 auto;
					display: inline-block;
					&:not(:last-child) {
						margin: 0 1rem 0 auto;
					}
				}
			}
		}
	}
