.two-column-list-section {

	&__header {
		border-top: solid 1px $silver;
		text-align: center;

		&__title {
			background-color: $white;
			color: $yellow;
			display: inline-block;
			font-size: 2rem;
			font-weight: 900;
			line-height: 1;
			margin: auto;
			padding: .5rem 1rem;
			position: relative;
			text-transform: uppercase;
			top: -1.7rem;
		}
	}

	&__column {
		margin-bottom: 4rem;
	}
}
