.pagination {
	display: block;
	width: 100%;
	padding: 1rem 0;

	&__page-number-holder {
		list-style: none;
		text-align: center;

		&__number-item {
			color: $black;
			text-decoration: none;
			font-weight: 700;
			display: inline-block;
			vertical-align: middle;
			padding: 0 .25rem;
			cursor: pointer;

			&:hover > a {
				text-decoration: underline;
				color: $dark-yellow;
			}

			a {
				color: inherit;
				text-decoration: inherit;
			}

			&--current {
				color: $yellow;
				text-decoration: underline;
			}
		}
	}

	&__prev {
		color: $black;
		text-decoration: none;
		font-weight: 700;
		text-align: left;


		a {
			color: inherit;
			text-decoration: inherit;

			&:hover {
				color: $yellow;
			}
		}
	}

	&__next {
		color: $black;
		text-decoration: none;
		font-weight: 700;
		text-align: right;


		a {
			color: inherit;
			text-decoration: inherit;

			&:hover {
				color: $yellow;
			}
		}
	}

}
