.statistics-section {
	//
	background-color: $lightest-gray;
	padding: 4.75rem 1rem;
	width: 100%;

	&__header {
		color: $yellow;
		font-size: 1.125rem;
	}

	&__content {
		//
		&__number {
			color: $pink;
			line-height: 1;
			margin-bottom: 1rem;

			@include below($m) {
				font-size: 6rem;
			}

			@include below($s) {
				font-size: 4rem;
			}
		}

		&__sub-heading {
			font-size: 1.5rem;
			margin-bottom: 1.5rem;
		}

		&__source {
			font-size: 1.125rem;
			text-transform: uppercase;
		}
	}
}
